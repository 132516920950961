<template>
    <div class="content">
        <div class="header">
            <a-select default-value @change="changeSelect" style="width: 200px;margin-right: 10px">
                <a-select-option value>全部类型</a-select-option>
                <a-select-option
                    :value="item.value"
                    v-for="item in typeList"
                    :key="item.value"
                >{{ item.name }}</a-select-option>
            </a-select>

            <a-input-search
                placeholder="搜索名称"
                style="width: 200px;margin-right: 10px"
                @search="onSearch"
            />
            <a-button type="primary" @click="add" :loading="loadingAdd">添加</a-button>
        </div>

        <div class="main">
            <div style="background: #fff;padding:10px">
                <a-table
                    :columns="columns"
                    :row-key="record => record.code"
                    :data-source="data"
                    :pagination="pagination"
                    :loading="loading"
                    @change="handleTableChange"
                    :scroll="{ y: tableHeight }"
                >
                    <template slot="status" slot-scope="status,record">
                        <span v-if="status !== '0'">停用</span>
                        <a-switch
                            :default-checked="status === '0'"
                            @change="val => changeStatus(val, record)"
                            :loading="loadingStatus === record.code"
                            style="margin: 0 10px;"
                        />
                        <span v-if="status === '0'">启用</span>
                    </template>

                    <template slot="file" slot-scope="name,record">
                        <a :href="record.file" target="_blank" v-if="record.file">{{name}}</a>
                        <a :href="record.img" target="_blank" v-else>{{record.img_name}}</a>
                    </template>

                    <template slot="option" slot-scope="option,record">
                        <a-popconfirm
                            title="确定要删除该数据?"
                            ok-text="确定"
                            cancel-text="我再想想"
                            @confirm="remove(record.code)"
                        >
                            <a-button type="link" style="color: red" :loading="loadingRemove === record.code">删除</a-button>
                        </a-popconfirm>
                        <a-button type="link" @click="edit(record)">编辑</a-button>
                    </template>
                </a-table>
            </div>
        </div>

        <edit-modal
          v-model="isShowModal"
          @done="doneModal"
          :item="editItem"
          :typeList='typeList'
          @cancel="cancelEdit"
        />
    </div>
</template>

<script>
import { publishList, updatePublish, removePublish, getNewsCategory } from '@/api/admin'
import EditModal from './views/EditModal'
export default {
  components: {
    EditModal
  },
  data () {
    return {
      isShowModal: false,
      loadingEdit: false,
      editItem: null,
      typeList: [],
      params: {
        page: 1,
        per_page: 10,
        name: '', // 名称
        type: '0', // 0:后台 1:网页
        publish_type: '' //  类型 1 使用说明 2 科研成果 3 平台孵化 4 核心竞争力 5 企业文化
      },
      loadingAdd: false,
      data: [],
      pagination: {
        total: 0,
        current: 1,
        showLessItems: true
      },
      loading: false,
      columns: [
        {
          title: '名称',
          dataIndex: 'name'
        },
        {
          title: '类型',
          dataIndex: 'publish_type_str'
        },
        {
          title: '简介',
          dataIndex: 'introduce'
        },
        {
          title: '文件',
          dataIndex: 'file_name',
          scopedSlots: { customRender: 'file' }
        },
        {
          title: '排序值',
          dataIndex: 'sort'
        },
        {
          title: '状态',
          dataIndex: 'status',
          scopedSlots: { customRender: 'status' },
          align: 'center'
        },
        {
          title: '发布时间',
          dataIndex: 'create_time'
        },
        {
          title: '发布人',
          dataIndex: 'create_by'
        },
        {
          title: '操作',
          dataIndex: 'option',
          scopedSlots: { customRender: 'option' },
          width: '160px',
          align: 'center'
        }
      ],
      loadingStatus: false,
      loadingRemove: false,
      tableHeight: 0
    }
  },
  created () {
    this.getType()
    this.getList()
  },
  mounted () {
    this.tableHeight = document.body.clientHeight - 330
  },
  methods: {
    async getType () {
      const data = await getNewsCategory({ classify_type: ['2', '3', '4', '5', '6'] }) // 1:新闻资讯 2:平台介绍 3:解决方案 4:关于我们 5其他

      this.typeList = (data || []).map(item => (
        {
          name: item.name,
          value: item.code
        }
      ))
    },
    getList () {
      this.loading = true
      this.params.page = this.pagination.current
      publishList(this.params).then(res => {
        this.loading = false
        this.data = res.list
        this.pagination.total = res.count
      }).catch(err => {
        this.loading = false
        this.$message.error(err.msg)
      })
    },
    remove (code) {
      this.loadingRemove = code
      removePublish({
        code
      }).then(res => {
        this.loadingRemove = ''
        this.$message.success('删除成功')
        this.getList()
      }).catch(err => {
        this.loadingRemove = ''
        this.$message.error(err.msg)
      })
    },
    changeStatus (value, item) {
      this.loadingStatus = item.code
      const status = value ? '0' : '1'
      const params = Object.assign({}, item)
      params.status = status
      delete params.publish_type_str
      updatePublish(params).then(res => {
        this.loadingStatus = ''
        item.status = status
      }).catch(err => {
        this.loadingStatus = ''
        this.$message.error(err.msg)
      })
    },
    changeSelect (value) {
      this.params.publish_type = value
      this.pagination.current = 1
      this.getList()
    },
    onSearch (value) {
      this.params.name = value
      this.pagination.current = 1

      this.getList()
    },
    add () {
      this.isShowModal = true
    },
    edit (item) {
      this.editItem = item
      this.isShowModal = true
    },
    cancelEdit () {
      this.editItem = {
        status: '0',
        sort: 1
      }
    },
    doneModal () {
      this.editItem = {
        status: '0',
        sort: 1
      }
      this.getList()
    },
    handleTableChange (e) {
      this.pagination.current = e.current
      this.getList()
    }
  }
}
</script>

<style lang="scss" scoped>
.content {
    .header {
        position: fixed;
        background: #fff;
        right: 0;
        left: 0;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        height: 60px;
        padding: 0 30px;
        z-index: 1;
    }

    .main {
        padding: 15px;
        padding-top: 90px;
    }
}
</style>
