<template>
    <a-modal
        :title="form.code? '编辑' : '添加'"
        :visible="isShowModal"
        :confirm-loading="loading"
        @ok="confirm"
        @cancel="cancel"
        cancel-text="取消"
        ok-text="保存"
        :mask-closable="false"
        :keyboard="false"
        :closable="false"
        :destroy-on-close="true"
    >
        <a-form-model
            ref="ruleForm"
            :model="form"
            :rules="rules"
            :label-col="{ span: 4 }"
            :wrapper-col="{ span: 19, offset: 1 }"
        >
            <a-form-model-item prop="name" label="名称">
                <a-input v-model="form.name" placeholder="最多不超过40个字" :max-length="40" />
            </a-form-model-item>

            <a-form-model-item prop="publish_type" label="类型">
                <a-select
                    :default-value="form.publish_type"
                    @change="val => form.publish_type = val"
                >
                    <a-select-option
                        :value="item.value"
                        v-for="item in typeList"
                        :key="item.value"
                    >{{ item.name }}</a-select-option>
                </a-select>
            </a-form-model-item>

            <a-form-model-item prop="introduce" label="简介">
                <a-input
                    v-model="form.introduce"
                    placeholder="最多不超过150个字"
                    :max-length="150"
                    type="textarea"
                />
            </a-form-model-item>
            <a-form-model-item prop="file" label="上传文件" key="1" v-if="form.publish_type === '7' || form.publish_type === '19' || form.publish_type === '20' || form.publish_type === '21'">
                <a-upload
                    :before-upload="file => upload(file, 'file')"
                    :disabled="uploading === 'file'"
                    :remove="() => removeUpload('file')"
                    :file-list="defaultUpload('file')"
                >
                    <a-button :loading="uploading === 'file'">上传</a-button>
                    <span class="upload-tips">仅支持上传一个文件，最大不超过20m</span>
                </a-upload>
            </a-form-model-item>

            <a-form-model-item
                prop="icon"
                label="图标"
                key="2"
                v-if="form.publish_type === '5'"
            >
                <a-upload
                    accept="image/*"
                    :before-upload="file => upload(file, 'icon')"
                    :disabled="uploading === 'icon'"
                    list-type="picture"
                    :remove="() => removeUpload('icon')"
                    :file-list="defaultUpload('icon')"
                >
                    <a-button :loading="uploading === 'icon'">上传</a-button>
                    <span class="upload-tips">仅支持上传一个文件，最大不超过2m；建议尺寸 48*48</span>
                </a-upload>
            </a-form-model-item>

            <a-form-model-item prop="img" label="展示图片" key="3" v-if="form.publish_type !== '7' && form.publish_type !== '19' && form.publish_type !== '20'">
                <a-upload
                    accept="image/*"
                    :before-upload="file => upload(file, 'img')"
                    :disabled="uploading === 'img'"
                    list-type="picture"
                    :remove="() => removeUpload('img')"
                    :file-list="defaultUpload('img')"
                >
                    <a-button :loading="uploading === 'img'">上传</a-button>
                    <span class="upload-tips">仅支持上传一个文件，最大不超过2m；建议尺寸{{form.publish_type === '18' ? '180*80' : '379*536'}}</span>
                </a-upload>
            </a-form-model-item>

            <a-form-model-item prop="status" label="状态">
                <span v-if="form.status === '1'" style="margin-right: 10px">关闭</span>
                <a-switch
                    :default-checked="form.status === '0'"
                    @change="val => form.status = val ? '0' : '1'"
                />
                <span v-if="form.status === '0'" style="margin-left: 10px">开启</span>
            </a-form-model-item>

            <a-form-model-item prop="sort" label="排序">
                <a-input v-model.number="form.sort" placeholder="排序" type="number" />
            </a-form-model-item>
        </a-form-model>
    </a-modal>
</template>

<script>
import upload from '@/plugins/upload'
import { addPublish, updatePublish } from '@/api/admin'
import { getUserInfo } from '@/utils/auth'

export default {
  props: {
    value: Boolean,
    item: {
      type: Object,
      default: () => ({})
    },
    typeList: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      loading: false,
      uploading: '',
      form: {
        code: '',
        name: '',
        introduce: '',
        file: '',
        icon: '',
        img: '',
        status: '0',
        publish_type: '7', // 默认是 使用说明 类型
        sort: 1,
        create_by: getUserInfo(),
        file_name: '',
        icon_name: '',
        img_name: ''
      },
      rules: {
        name: [
          { required: true, message: '请填写名称', trigger: 'change' }
        ],
        publish_type: [{ required: true, message: '请选择类型', trigger: 'change' }],
        introduce: [{ required: true, message: '请填写简介', trigger: 'change' }],
        file: [{ required: true, message: '请上传文件', trigger: 'change' }],
        status: [{ required: true, message: '请选择状态', trigger: 'change' }],
        sort: [{ required: true, message: '请填写排序', trigger: 'change' }],
        img: [{ required: true, message: '请上传展示图片', trigger: 'change' }],
        icon: [{ required: true, message: '请上传图标', trigger: 'change' }]
      }
    }
  },
  watch: {
    item (val) {
      if (val) {
        console.log(val, 'val')
        for (const key in this.form) {
          this.form[key] = this.item[key]
        }
      }
    }
  },
  computed: {
    isShowModal: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    defaultUpload () {
      return (type) => {
        return this.form[type] ? [
          {
            uid: '1',
            url: this.form[type],
            status: 'done',
            name: this.form[`${type}_name`]
          }
        ] : []
      }
    }
  },
  methods: {
    sendDate () {
      return this.form.code ? updatePublish : addPublish
    },
    confirm () {
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          this.loading = true
          console.log(this.sendDate(), 'this.sendDate()')
          const func = this.sendDate()
          func(this.form).then(res => {
            this.loading = false
            this.$message.success('保存成功')
            this.$emit('done')
            this.isShowModal = false
          }).catch(err => {
            this.loading = false
            this.$message.error(err.msg)
          })
        }
      })
    },
    upload (file, type) {
      // console.log(upload)
      // return false
      // return new Promise((resolve, reject) => {
      // if (type === 'file' && file.size > 20 * 1024 * 1024) {
      if (type === 'file' && file.size > 500 * 1024 * 1024) {
        // reject()
        return this.$message.error('文件大小超过限制')
      }

      if ((type === 'icon' || type === 'img') && file.size > 2 * 1024 * 1024) {
        // reject()
        return this.$message.error('文件大小超过限制')
      }

      this.uploading = type

      this.form[type] = file

      upload(file).then(url => {
        this.form[type] = url
        this.form[`${type}_name`] = file.name
        // resolve(url)
        this.uploading = ''
      }).catch(err => {
        // reject()
        this.$message.error(err.msg)
        this.uploading = ''
      })
      // })
      return false
    },
    removeUpload (type) {
      this.form[type] = ''
      this.form[`${type}_name`] = ''
    },
    cancel () {
      this.isShowModal = false
      //   this.form = {
      //     name: '',
      //     introduce: '',
      //     file: '',
      //     icon: '',
      //     img: '',
      //     status: '0',
      //     publish_type: '7', // 默认是 使用说明 类型
      //     sort: 1,
      //     create_by: '',
      //     file_name: '',
      //     icon_name: '',
      //     img_name: ''
      //   }
      this.$emit('cancel')
    }
  }
}
</script>

<style lang="scss" scoped>
.upload-tips {
    font-size: 12px;
    color: #999;
    padding-left: 10px;
}
</style>
